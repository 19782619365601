/**
 * Created by Stepan
 */

var YandexSocial = function () {
    Social.call(this, connector.YANDEX, {
        sdk: SdkManager.SDK_YANDEX
    });
};

YandexSocial.prototype = Object.create(Social.prototype);
YandexSocial.prototype.constructor = YandexSocial;

YandexSocial.prototype._connect = function (callback) {
    this.initPlayer(function (code) {
        callback(code === connector.CODE_SUCCEED ? connector.STATUS_CONNECTED : connector.STATUS_DISCONNECTED);
    });
};

YandexSocial.prototype.initPlayer = function (callback) {
    ysdk.getPlayer({ signed: true }).then(function (player) {
        this.playerMode = player.getMode();
        this.payingStatus = player.getPayingStatus();
        this.accessToken = player.signature;

        // For some reason playerMod could stay "lite" even after yandex reports allegedly successful login, stay unlogged
        if (this.playerMode === "lite") {
            delete this.user;
        } else {
            this.user = YandexSocial.formatUserData(player);

            ysdk.notifications.allow();
        }

        callback(connector.CODE_SUCCEED);
    }.bind(this)).catch(function () {
        callback(connector.CODE_FAILED);
    });
};

YandexSocial.prototype._login = function (callback) {
    ysdk.auth.openAuthDialog().then(function () {
        this.initPlayer(callback);
    }.bind(this)).catch(callback);
};

YandexSocial.prototype.isLoggedIn = function () {
    return this.user !== undefined;
};

YandexSocial.prototype._getUserID = function () {
    return this.user ? this.user.id : undefined;
};

YandexSocial.prototype.getAccessToken = function () {
    return "YA_" + (this.accessToken || 0);
};

YandexSocial.prototype._aboutMe = function (callback) {
    if (this.user) {
        callback(connector.CODE_SUCCEED, this.user);
    } else {
        callback(connector.CODE_FAILED);
    }
};

YandexSocial.formatUserData = function (user) {
    return {
        id: user.getUniqueID(),
        name: user.getName(),
        avatar: user.getPhoto("small")
    };
};
