/**
 * Created by mac on 4/8/18
 */

var VideoAds = function () {
    AdsPlugin.call(this, connector.TEST);

    this.bannerProbability = 1;
};

VideoAds.prototype = Object.create(AdsPlugin.prototype);
VideoAds.prototype.constructor = VideoAds;

VideoAds.isAppropriate = function () {
    return connector.platform.oneOf(connector.TEST);
};

VideoAds.prototype.isStickySupported = function () {
    return true;
};

VideoAds.prototype.isStickyAvailable = function () {
    return true;
};

VideoAds.prototype._connect = function (callback) {
    setTimeout(function () {
        callback(connector.STATUS_CONNECTED);
    }, 0);
};

VideoAds.prototype.isInterstitialSupported = function () {
    return true;
};

VideoAds.prototype.isRewardedSupported = function () {
    return true;
};

VideoAds.prototype._cacheAd = function (name, callback) {
    setTimeout(function () {
        callback(connector.CODE_SUCCEED, true);
    }, 0);
};
VideoAds.prototype._playAd = function (name, ad, callback) {
    var overlay = cleverapps.UI.createScale9Sprite(bundles.pixel.frames.pixel_png);
    overlay.setAnchorPoint(0, 0);
    overlay.setColor(cc.color(200, 200, 255));
    overlay.setLocalZOrder(Number.MAX_VALUE);
    overlay._setGlobalZOrder(true);

    var scene = cc.director.getRunningScene();
    scene.addChild(overlay);

    if (this.banner) {
        this.banner.hidden = true;
    }

    var stop = connector.utils.once(function (code) {
        clearTimeout(rewardTimeout);

        overlay.removeFromParent();
        if (this.banner) {
            this.banner.hidden = false;
        }

        if (code === connector.CODE_SUCCEED) {
            callback(connector.CODE_SUCCEED);
        } else if (name === AdsPlugin.REWARDED) {
            callback(ERRORS.SHOW_REWARDED.CLOSED_BY_USER);
        } else {
            callback(ERRORS.SHOW_INTERSTITIAL.CLOSED_BY_USER);
        }
    }.bind(this));
    var rewardTimeout = setTimeout(function () {
        stop(connector.CODE_SUCCEED);
    }, 3000);

    var skipText = name === AdsPlugin.REWARDED ? "skip >>" : "tap anywhere to skip";
    var skip = cleverapps.UI.generateOnlyText(skipText, cleverapps.styles.FONTS.WHITE_TEXT);
    skip.setPosition(overlay.width / 2, overlay.height * 0.2);
    overlay.addChild(skip);

    if (name === AdsPlugin.REWARDED) {
        skip.alwaysOn = true;
        cleverapps.UI.onClick(skip, function () {
            stop(ERRORS.SHOW_REWARDED.CLOSED_BY_USER);
        });
        cleverapps.UI.applyHover(skip);
    } else if (name === AdsPlugin.INTERSTITIAL) {
        var snapshotBar = new SnapshotBarView();
        overlay.movingNode = new cc.Node();
        overlay.addChild(overlay.movingNode);

        overlay.addChild(snapshotBar);
        snapshotBar.performRecursive(function (node) {
            node.alwaysOn = true;
        });

        overlay.alwaysOn = true;
        cleverapps.UI.onClick(overlay, function () {
            stop(ERRORS.SHOW_INTERSTITIAL.CLOSED_BY_USER);
        }, { interactiveScale: false });
    }

    name = cleverapps.rewardedAdsManager.prerollStatus === RewardedAdsManager.PREROL_SHOWING ? name + " (prerol)" : name;
    var nameNode = cleverapps.UI.generateOnlyText(name, cleverapps.styles.FONTS.WHITE_TEXT);
    overlay.addChild(nameNode);

    overlay.updateSize = function () {
        var sreenRect = cleverapps.resolution.getBgRect();
        if (overlay.movingNode) {
            overlay.movingNode.setPosition(0, Math.abs(sreenRect.y));
        }

        overlay.setContentSize2(sreenRect);
        overlay.setPositionRound(sreenRect);

        skip.setPosition(overlay.width / 2, overlay.height * 0.2);
        nameNode.setPosition(overlay.width / 2, overlay.height * 0.8);
    };
    overlay.updateSize();
};

VideoAds.prototype.setBannerProbability = function (probability) {
    this.bannerProbability = probability;
};

VideoAds.prototype.setBannerSize = function (bannerSize) {
    this.bannerSize = bannerSize;
};

VideoAds.prototype.showSticky = function (callback) {
    var prob = this.bannerProbability || 0.3;
    if (Math.random() > prob) {
        callback && callback(ERRORS.SHOW_STICKY.NO_STICKY);
        return;
    }

    this.closeSticky();

    var bannerSize = this.bannerSize || {
        width: Math.min(728, Math.round(window.innerWidth * 0.95)),
        height: 90
    };

    var banner = this.banner = document.createElement("div");
    document.body.appendChild(banner);
    banner.style.width = bannerSize.width + "px";
    banner.style.maxWidth = bannerSize.width + "px";
    banner.style.height = bannerSize.height + "px";
    banner.style.maxHeight = bannerSize.height + "px";
    banner.style.position = "absolute";
    banner.style.bottom = "0px";
    banner.style.left = "50%";
    banner.style.marginLeft = -bannerSize.width / 2 + "px";
    banner.style.backgroundColor = "white";
    banner.style.zIndex = "10";

    callback && callback(connector.CODE_SUCCEED, bannerSize);
};

VideoAds.prototype.closeSticky = function (callback) {
    if (this.banner) {
        document.body.removeChild(this.banner);
        delete this.banner;
    }

    callback && callback(connector.CODE_SUCCEED);
};
