/**
 * Created by vladislav on 2/9/2024
 */

/**
 *
 * Platform manager
 *
 * ## Get User's ID on the platform
 *
 * ```
 * connector.platform.getUserID();
 * ```
 *
 * ## Platforms with native loading screens
 *
 * <ul class="platforms-list">
 *   <li>
 *       ✅ <img src="images/instant.png"> Facebook Instant Games
 *   </li>
 *   <li>
 *       ✅ <img src="images/crazy.png"> Crazy Games
 *   </li>
 *   <li>
 *       ✅ <img src="images/samsung.png"> Samsung
 *   </li>
 * </ul>
 *
 * Some platforms have their own loading screens, so you need to report your game's loading progress.
 *
 * ```
 * connector.platform.reportLoadingProgress(10);
 * connector.platform.reportLoadingProgress(50);
 * connector.platform.reportLoadingProgress(100);
 *
 * // When your game is loaded
 * await connector.platform.startGame();
 * ```
 *
 * ## Notify platforms when game is ready
 *
 * <ul class="platforms-list">
 *   <li>
 *       ✅ <img src="images/yandex.png"> Yandex Games
 *   </li>
 *   <li>
 *       ✅ <img src="images/youtube.png"> YouTube
 *   </li>
 * </ul>
 *
 * On some platforms you need to notify them when your game is fully interactable and playable.
 *
 * ```
 * connector.platform.notifyGameReady();
 * ```
 *
 * ## Pause
 *
 * You have access to the pause control mechanism in the game.
 * The SDK has a "Paused" flag, by itself it means nothing, however, you can engage in it if you need to pause and continue the game.
 * Additionally, the SDK will notify you when a pause or resume has been triggered.
 *
 * ```
 * connector.platform.isPaused;
 *
 * // Pause the game
 * connector.platform.pause();
 * // Resume the game
 * connector.platform.resume();
 *
 * // Paused the game
 * connector.platform.on("pause", () => {});
 * // Resumed the game
 * connector.platform.on("resume", () => {});
 *
 * ```
 *
 * @namespace Platform
 * */

var PlatformInterface = function () {
    return Object.assign({
        /**
         * @type {Boolean}
         * @memberof Platform
         * @desc Game pauses when page loses focus or app is minimized. You can also pause it with connector.platform.pause().
         * */
        get isPaused() {
            return this.getPlugin().isPaused();
        },
        /**
         * @type {Boolean}
         * @memberof Platform
         * @desc Indicates whether the platform can control inGame audio by disabling or enabling it.
         * */
        get isAudioEnabled() {
            return this.getPlugin().isAudioEnabled();
        },
        /**
         * @type {Boolean}
         * @memberof Platform
         * @desc If the platform has a dedicated audio control switch, indicates whether audio is currently enabled
         * */
        get hasAudioControls() {
            return this.getPlugin().hasAudioControls();
        }
    }, CommonInterface(), {
        _detectPlugin: PlatformDetector,

        /**
         * @function getUserID
         * @memberof Platform
         * @desc Returns userID
         * @return {string}
         * */
        getUserID: function () {
            return this.getPlugin().getUserID();
        },

        setUserID: function (userID) {
            this.getPlugin().setUserID(userID);
        },

        withTmpID: function () {
            var userID = connector.platform.getUserID();
            if (userID) {
                return userID.startsWith("__");
            }

            return false;
        },

        withDeviceID: function () {
            var userID = connector.platform.getUserID();

            var deviceId = connector.info.deviceID;

            if (deviceId) {
                return userID === deviceId;
            }

            return false;
        },

        getAccessToken: function () {
            if (connector.platform.withDeviceID()) {
                return this.getPlugin().getAccessToken();
            }

            return connector.social.getPlugin().getAccessToken();
        },

        /**
         * @function pause
         * @memberof Platform
         * @desc Pauses the game
         * */
        pause: function () {
            this.getPlugin().pause();
        },
        /**
         * @function resume
         * @memberof Platform
         * @desc Resume the game
         * */
        resume: function () {
            this.getPlugin().resume();
        },

        callNative: function (method, options, callback) {
            this.getPlugin().callNative(method, options, callback);
        },

        subscribeNative: function (method, callback) {
            this.getPlugin().nativeEventListeners[method] = callback;
        },

        unsubscribeNative: function (method) {
            delete this.getPlugin().nativeEventListeners[method];
        },
        /**
         * @function isTester
         * @memberof Platform
         * @desc Whether the player is a tester. Testers can be specified in config.testers
         * */
        isTester: function () {
            return this.getPlugin().isTester();
        },
        /**
         * @function openUrl
         * @memberof Platform
         * @desc Opens external url
         * */
        openUrl: function (url) {
            this.getPlugin().openUrl(url);
        },
        /**
         * @function locationReload
         * @memberof Platform
         * @desc Reloads page
         * */
        locationReload: function () {
            this.getPlugin().locationReload();
        },
        /**
         * @function listSuggestedLanguages
         * @memberof Platform
         * @desc Returns list of suggested languages for the player.
         * @return {Array<string>}
         * */
        listSuggestedLanguages: function () {
            return this.getPlugin().listSuggestedLanguages();
        },

        parsePayload: function (callback) {
            this.getPlugin().parsePayload(callback);
        },

        calcPromo: function (callback) {
            this.parsePayload(function (data) {
                var promo = data && data.promo;

                if (promo) {
                    callback(promo);
                } else {
                    this.parseOtherWays(callback);
                }
            }.bind(this));
        },

        parseOtherWays: function (callback) {
            this.getPlugin().parseOtherWays(callback);
        },

        hasCloseApplication: function () {
            return this.getPlugin().closeApplication;
        },

        closeApplication: function () {
            if (this.getPlugin().closeApplication) {
                this.getPlugin().closeApplication();
            }
        },
        /**
         * @function reportLoadingProgress
         * @memberof Platform
         * @desc Updates loading progress on platform loading screen.
         *
         * Platforms: Samsung, Crazy, Instant.
         * @param {number} percent - Loading progress
         * */
        reportLoadingProgress: function (percent) {
            if (this.getPlugin().reportLoadingProgress) {
                this.getPlugin().reportLoadingProgress(percent);
            }
        },
        /**
         * @function startGame
         * @memberof Platform
         * @desc Needs to be called on some platforms when the game finished loading.
         *
         * Platforms: Samsung, Crazy, Instant.
         * */
        startGame: function () {
            return new Promise(function (resolve) {
                this.startGameCallback(resolve);
            }.bind(this));
        },

        startGameCallback: function (callback) {
            if (this.getPlugin().startGame) {
                this.getPlugin().startGame(callback);
            } else {
                callback();
            }
        },

        /**
         * @function getSafePadding
         * @memberof Platform
         * @desc Returns safe screen paddings e.g. { left: 20, right: 0, top: 50, bottom: 0}
         * @return {object}
         *
         * */
        getSafePadding: function () {
            return this.getPlugin().getSafePadding();
        },

        /**
         * @function getFrameSize
         * @memberof Platform
         * @desc Returns window frame size e.g. { width: 1920, height: 1080}
         * @return {object}
         *
         * */
        getFrameSize: function () {
            return this.getPlugin().getFrameSize();
        },

        getLocalStoragePrefix: function () {
            return this.getPlugin().getLocalStoragePrefix();
        },

        /**
         * @function canReview
         * @memberof Platform
         * @desc Whether review is available
         * @return {Boolean}
         * */
        canReview: function () {
            return this.getPlugin().canReview();
        },

        /**
         * @function requestReview
         * @memberof Platform
         * @desc Show a dialog that suggests a user to rate the game
         * @return {Promise<Boolean>}
         * */
        requestReview: function () {
            return new Promise(function (resolve) {
                this.requestReviewCallback(function (code) {
                    resolve(code === connector.CODE_SUCCEED);
                });
            }.bind(this));
        },

        requestReviewCallback: function (callback) {
            this.getPlugin().requestReview(callback);
        },

        /**
         * @function canCreateShortcut
         * @memberof Platform
         * @desc Whether shortcut is available
         * @return {Boolean}
         * */
        canCreateShortcut: function () {
            return this.getPlugin().canCreateShortcut();
        },
        /**
         * @function createShortcut
         * @memberof Platform
         * @desc Create shortcut on home screen or add game to favorites
         * @return {Promise<Boolean>}
         * */
        createShortcut: function () {
            return new Promise(function (resolve) {
                this.createShortcutCallback(function (code) {
                    resolve(code === connector.CODE_SUCCEED);
                });
            }.bind(this));
        },

        createShortcutCallback: function (callback) {
            this.getPlugin().createShortcut(callback);
        },

        /**
         * @function notifyGameReady
         * @memberof Platform
         * @desc Needs to be called on some platforms when the game is ready and playable.
         *
         * Platforms: Yandex, Youtube.
         * */
        notifyGameReady: function () {
            if (this.getPlugin().notifyGameReady && !this.getPlugin().notifyGameReadyDone) {
                this.getPlugin().notifyGameReadyDone = true;

                connector.platform.whenConnected(function () {
                    this.getPlugin().notifyGameReady();
                }.bind(this));
            }
        },
        /**
         * @function gameplayStart
         * @memberof Platform
         * @desc Has to be called whenever the player starts playing or resumes playing after a break (menu/loading/achievement screen, game paused, etc.)
         *
         * Platforms: Crazy.
         * */
        gameplayStart: function () {
            var plugin = this.getPlugin();
            if (plugin.gameplayStart) {
                plugin.gameplayStart();
            }
        },
        /**
         * @function gameplayStop
         * @memberof Platform
         * @desc Has to be called on every game break (entering a menu, switching level, pausing the game, etc.)
         *
         * Platforms: Crazy.
         * */
        gameplayStop: function () {
            var plugin = this.getPlugin();
            if (plugin.gameplayStop) {
                plugin.gameplayStop();
            }
        },

        switchGame: function (url, payload, callback) {
            this.getPlugin().switchGame(url, payload, callback);
        },

        getGameUrl: function (source) {
            return Platform.getGameUrl(source);
        },

        getRateUrl: function () {
            return Platform.getRateUrl();
        },

        getExternalUrl: function (url) {
            return Platform.getExternalUrl(url);
        }
    });
};