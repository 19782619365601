/**
 * Created by andrey on 15.05.2023
 */

var YooKassaPayments = function () {
    RestPayments.call(this, connector.YOOKASSA);

    connector.platform.subscribeNative("YooKassaPayments", this.onNativeEvent.bind(this));
};

YooKassaPayments.prototype = Object.create(RestPayments.prototype);
YooKassaPayments.prototype.constructor = YooKassaPayments;

YooKassaPayments.prototype.getDefaultCurrency = function () {
    return "RUB";
};

YooKassaPayments.prototype.purchase = function (product, callback, options) {
    if (product.type === "subscription") {
        callback(ERRORS.PURCHASE.UNKNOWN);
        return;
    }

    var idfa = connector.info.deviceID;
    var email = options.email;
    var merchantCustomerId = undefined;
    if (!connector.platform.withTmpID() || idfa) {
        merchantCustomerId = connector.platform.getUserID() + "_" + idfa + "_" + email;
    }

    var purchase = this.createPurchase(undefined, product);

    var data = {
        purchase: purchase,
        email: email,
        merchantCustomerId: merchantCustomerId,
        version: 2
    };

    console.log("YooKassaPayments.create", JSON.stringify(data));

    ConnectorRestClient.post("/yookassapayments/create", data, function (response) {
        console.log("YooKassaPayments.create response", JSON.stringify(response.error || response.payment || response));

        if (response.error && response.parameter === "receipt.customer.email") {
            callback(ERRORS.PURCHASE.INVALID_EMAIL);
            return;
        }

        if (response.error || !response.payment) {
            console.log(response.error || "empty payment");

            this.bus.trigger("stream:error", "YooKassaPayments.purchase productId:" + product.productId + " " + JSON.stringify(response.error || "empty payment"));

            callback(ERRORS.PURCHASE.UNKNOWN);
            return;
        }

        var payment = response.payment;

        purchase.paymentId = payment.paymentId;

        var confirmationToken = payment.confirmation && payment.confirmation.confirmation_token;

        console.log("YooKassaPayments.create Success: " + JSON.stringify(payment));

        if (["waiting_for_capture", "succeeded"].includes(payment.status)) {
            callback(connector.CODE_SUCCEED, purchase);
        } else if (["canceled"].includes(payment.status)) {
            callback(ERRORS.PURCHASE.CANCEL);
        } else if (payment.status === "pending" && purchase.paymentId) {
            this.openWidget(purchase, confirmationToken, callback);
        } else {
            callback(ERRORS.PURCHASE.UNKNOWN);
        }
    }.bind(this), function (error) {
        console.log(error);

        callback(connector.CODE_FAILED);
    });
};

YooKassaPayments.prototype.getFinishUrl = function (purchase) {
    var finishUrl = connector.config.payApiURL + "/yookassapayments/finish/" + encodeURIComponent(connector.platform.getUserID());

    var params = new URLSearchParams();
    if (connector.info.isNative) {
        var appLink = "cleverapps://" + connector.config.projectId;
        if (connector.config.debugMode) {
            appLink += "staging";
        }
        params.set("app_link", appLink);
    }

    if (purchase && purchase.paymentId) {
        params.set("paymentId", purchase.paymentId);
    }

    if (params.size > 0) {
        finishUrl += "?" + params.toString();
    }

    return finishUrl;
};

YooKassaPayments.prototype.getWidgetUrl = function (purchase, confirmationToken) {
    return connector.calcBaseUrl() + "res/yookassa/yookassa-widget.html"
        + "?confirmation_token=" + confirmationToken
        + "&payment_id=" + purchase.paymentId
        + "&finish_url=" + encodeURIComponent(this.getFinishUrl(purchase))
        + "&modal=" + (connector.platform.oneOf(connector.CLEVERAPPS, connector.TEST));
};

YooKassaPayments.prototype.openWidget = function (purchase, confirmationToken, callback) {
    this.onWidgetClosedListener = function () {
        console.log("YooKassaPayments.onWidgetClosedListener - " + this.getPurchaseState());
        if (this.getPurchaseState() === PaymentsManager.STATE_PURCHASE) {
            this.startStatusPinger(purchase, callback);
        }
    }.bind(this);

    this.setNotifyActiveListener(this.onWidgetClosed.bind(this));

    var widgetUrl = this.getWidgetUrl(purchase, confirmationToken);
    if (connector.platform.oneOf(connector.ANDROID)) {
        connector.platform.callNative("YooKassaPlugin.openWidget", { widgetUrl: widgetUrl });
        return;
    }

    var frame = document.createElement("iframe");
    frame.style.height = "100%";
    frame.style.width = "100%";
    frame.style.zIndex = "1000000000";
    frame.style.border = "none";
    frame.src = widgetUrl;
    frame.id = "YooKassaFrame";
    frame.allow = "payment";
    document.body.appendChild(frame);

    var messageHandler = function (event) {
        if (event.data.action === "close") {
            window.removeEventListener("message", messageHandler);
            if (frame.parentNode) {
                frame.parentNode.removeChild(frame);
            }
            this.onWidgetClosed();
        }
    }.bind(this);

    window.addEventListener("message", messageHandler);
};

YooKassaPayments.prototype.onWidgetClosed = function () {
    if (this.onWidgetClosedListener) {
        this.onWidgetClosedListener();
        delete this.onWidgetClosedListener;
    }
};

YooKassaPayments.prototype.stopStatusPinger = function () {
    if (this.statusPinger) {
        this.statusPinger.stop();
        delete this.statusPinger;
    }
};

YooKassaPayments.prototype.startStatusPinger = function (purchase, callback) {
    this.stopStatusPinger();

    var waitPurchaseStateEndTime = Date.now() + YooKassaPayments.WAIT_PURCHASE_STATE_TIMEOUT;

    this.statusPinger = new Pinger({
        action: function (callback) {
            console.log("YooKassaPayments.statusCheck: " + purchase.paymentId);
            var path = "/yookassapayments/get/" + purchase.paymentId;

            ConnectorRestClient.get(path, {}, function (response) {
                if (response.error || !response.payment) {
                    console.log(response.error || "no payment");
                    callback(ERRORS.PURCHASE.UNKNOWN);
                    return;
                }

                var payment = response.payment;

                console.log("YooKassaPayments.statusCheck success: " + JSON.stringify(payment));

                if (["waiting_for_capture", "succeeded"].includes(payment.status)) {
                    callback(connector.CODE_SUCCEED);
                } else if (["canceled"].includes(payment.status) || payment.purchaseState === "cancel") {
                    callback(ERRORS.PURCHASE.CANCEL);
                } else if (["initialize_error", "fail"].includes(payment.purchaseState)) {
                    callback(ERRORS.PURCHASE.UNKNOWN);
                } else if (Date.now() > waitPurchaseStateEndTime && !["finished", "success"].includes(payment.purchaseState)) {
                    console.log("YooKassaPayments.statusCheck wait purchaseState timeout");
                    callback(ERRORS.PURCHASE.TIMEOUT);
                } else {
                    callback(ERRORS.PURCHASE.UNKNOWN);
                }
            });
        },

        timeout: YooKassaPayments.WAIT_SUCCEEDED_TIMEOUT,

        callback: function (code) {
            callback(code, purchase);
        }
    });
    this.statusPinger.start();
};

YooKassaPayments.prototype.stopPurchaseActions = function () {
    RestPayments.prototype.stopPurchaseActions.apply(this, arguments);

    this.stopStatusPinger();
    delete this.onWidgetClosedListener;
};

YooKassaPayments.prototype.onNativeEvent = function (name) {
    switch (name) {
        case "widgetClosed":
            this.onWidgetClosed();
            break;
    }
};

YooKassaPayments.isAppropriate = function () {
    return connector._countryManager.country === "RU"
        && (connector.platform.oneOf(connector.ANDROID)
        || connector.platform.oneOf(connector.CLEVERAPPS));
};

YooKassaPayments.WAIT_PURCHASE_STATE_TIMEOUT = connector.utils.parseInterval("2 seconds");
YooKassaPayments.WAIT_SUCCEEDED_TIMEOUT = connector.utils.parseInterval("60 seconds");
