/**
 * Created by vladislav on 4/8/2024
 */

var Info = {
    nativePlatforms: ["microsoft", "gpg", "mygames", "macos", "wechat"],
    nativeMobilePlatforms: ["android", "amazon", "ios", "pliega", "rustore"],

    bus: new ConnectorEventEmitter(),

    initialize: function () {
        connector.info.source = this._detectSource();
        connector.info.isMobile = this._detectMobile();
        connector.info.isNative = this._detectNative();
    },

    _detectLanguage: function () {
        var language = window.navigator.language || window.navigator.browserLanguage;
        if (language) {
            language = language.split("-")[0];

            if (language === "du") {
                return connector.LANGUAGE_DUTCH;
            }

            return language;
        }

        return connector.LANGUAGE_ENGLISH;
    },

    _detectMobile: function () {
        if (Info.nativeMobilePlatforms.includes(connector.info.source)) {
            return true;
        }

        var ua = window.navigator.userAgent.toLowerCase();

        return /mobile|android|iphone|ipad/.test(ua);
    },

    _detectNative: function () {
        return Info.nativePlatforms.includes(connector.info.source) || Info.nativeMobilePlatforms.includes(connector.info.source);
    },

    _detectOS: function () {
        var nav = window.navigator;
        var ua = nav.userAgent.toLowerCase();

        var isAndroid = false;
        var iOS = false;
        var uaResult = /android (\d+(?:\.\d+)+)/i.exec(ua) || /android (\d+(?:\.\d+)+)/i.exec(nav.platform);
        if (uaResult) {
            isAndroid = true;
        }
        uaResult = /(iPad|iPhone|iPod).*OS ((\d+_?){2,3})/i.exec(ua);
        if (uaResult) {
            iOS = true;
        } else if (/(iPhone|iPad|iPod)/.exec(nav.platform)) {
            iOS = true;
        }

        var osName = connector.OS_UNKNOWN;
        if (nav.appVersion.indexOf("Win") !== -1) {
            osName = connector.OS_WINDOWS;
        } else if (iOS) {
            osName = connector.OS_IOS;
        } else if (nav.appVersion.indexOf("Mac") !== -1) {
            osName = connector.OS_OSX;
        } else if (nav.appVersion.indexOf("X11") !== -1 && nav.appVersion.indexOf("Linux") === -1) {
            osName = connector.OS_UNIX;
        } else if (isAndroid) {
            osName = connector.OS_ANDROID;
        } else if (nav.appVersion.indexOf("Linux") !== -1) {
            osName = connector.OS_LINUX;
        }

        return osName;
    },

    _detectSource: function () {
        var existParameterByName = function (name) {
            return window.location.href.indexOf(name) !== -1;
        };

        if (connector.config.source === "msstart" && !existParameterByName("msstart_sdk_init")) {
            return "pwa";
        }

        if (connector.config.debugMode && connector.config.source === "plinga") {
            return "plng";
        }

        if (connector.config.source) {
            return connector.config.source;
        }

        if (window.location.href.includes("apps.fbsbx.com")) {
            return "instant";
        }

        if (window.location.href.includes("coolmathgames.com")) {
            return "coolmath";
        }

        var paramsSource = new URLSearchParams(window.location.search).get("source");
        if (["android", "amazon", "ios", "macos", "plng", "pliega", "rustore", "gdcom", "mygames",
            "kongregate", "msstart", "microsoft", "crazy", "facebook", "xiaomi", "cleverapps", "wortal", "samsung", "youtube", "gamesnack"].includes(paramsSource)) {
            return paramsSource;
        }

        if (existParameterByName("msstart_sdk_init")) {
            return "msstart";
        }

        if (existParameterByName("dr_auth_code")) {
            return "draugiem";
        }

        if (existParameterByName("logged_user_id")) {
            return "ok";
        }

        if (existParameterByName("api.vk.")) {
            return "vk";
        }

        var yandexAppId = connector.config.yandex && connector.config.yandex.appId;
        if (existParameterByName("yandex") || existParameterByName("playhop.com")
            || yandexAppId && existParameterByName("app-id=" + yandexAppId)) {
            return "yandex";
        }

        if (existParameterByName("fotostrana.ru")) {
            return "fotostrana";
        }

        if (existParameterByName("source=spmobage")) {
            return "sp_mbga";
        }

        if (existParameterByName("mbga-platform.jp")) {
            return "mbga";
        }

        if (existParameterByName("source=xsolla")) {
            return "cleverapps";
        }

        if (connector.config.mm && existParameterByName("app_id=" + connector.config.mm.appId)) {
            return "mm";
        }

        if (existParameterByName("telegram=true")) {
            return "ton";
        }

        if (connector.config.testPlatform) {
            return "test";
        }

        if (connector.config.facebook && connector.config.facebook.appId && connector.utils.isIFrame()) {
            return "facebook";
        }

        return "cleverapps";
    }
};