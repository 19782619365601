/**
 * Created by Andrey Popov on 03.11.2023
 */

var CrazySocial = function () {
    Social.call(this, connector.CRAZY, {
        sdk: SdkManager.SDK_CRAZY
    });
};

CrazySocial.prototype = Object.create(Social.prototype);
CrazySocial.prototype.constructor = CrazySocial;

CrazySocial.prototype._connect = function (callback) {
    if (!this.hasAuthListener) {
        CrazyGames.SDK.user.addAuthListener(function () {
            if (!this.isConnected() || this.loginRunning) {
                return;
            }

            this.loadUser(function () {
                if (this.isLoggedIn()) {
                    this.onLogin();
                }
            }.bind(this));
        }.bind(this));

        this.hasAuthListener = true;
    }

    this.loadUser(function () {
        callback(connector.STATUS_CONNECTED);
    });
};

CrazySocial.prototype._login = function (cb) {
    this.loginRunning = true;

    var callback = function () {
        this.loginRunning = false;
        cb();
    }.bind(this);

    CrazyGames.SDK.user.showAuthPrompt(function (error) {
        if (error) {
            console.log("CrazySocial login error:", error);

            callback();
            return;
        }

        this.loadUser(callback);
    }.bind(this));
};

CrazySocial.prototype.loadUser = function (callback) {
    // console.warn("getUserToken");

    if (this.isLoggedIn()) {
        callback();
        return;
    }

    CrazyGames.SDK.user.getUserToken(function (error, token) {
        if (error) {
            console.log("CrazyGames getUserToken error:", error);
            callback(error);
            return;
        }

        ConnectorRestClient.get(
            "/crazy/decodetoken/" + token,
            {},
            function (response) {
                this.accessToken = token;
                this.user = CrazySocial.formatUserData(response);
                callback();
            }.bind(this),
            function (error) {
                callback(error);
            }
        );
    }.bind(this));
};

CrazySocial.formatUserData = function (userData) {
    if (!userData.userId) {
        return undefined;
    }

    return {
        id: "" + userData.userId,
        name: userData.username,
        firstName: userData.username,
        avatar: userData.profilePictureUrl
    };
};

CrazySocial.prototype._aboutMe = function (callback) {
    if (this.user) {
        callback(connector.CODE_SUCCEED, this.user);
    } else {
        callback(connector.CODE_FAILED);
    }
};

CrazySocial.prototype.markAchievement = function () {
    CrazyGames.SDK.game.happytime();
};

CrazySocial.prototype.getAccessToken = function () {
    return "CRAZY_" + this.accessToken;
};

CrazySocial.prototype.isLoggedIn = function () {
    return Boolean(this.user);
};

CrazySocial.prototype._getUserID = function () {
    return this.user && this.user.id;
};