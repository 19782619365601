/**
 * Created by vladislav on 2/7/2024
 */

connector.config = {
};

connector.sdk = new SdkManager();

connector.player = PlayerInterface();
connector.info = InfoInterface();
connector.platform = PlatformInterface();
connector.social = SocialInterface();
connector.payments = PaymentsInterface();
connector.ads = AdsInterface();
connector.storage = StorageInterface();

connector._playerManager = new PlayerManager();
connector._platformStorage = new PlatformStorage();

connector.initialize = function (options, callback) {
    this.config = options.config;

    if (this.config.name !== this.config.projectId) {
        throw 1;
    }

    if (!this.config.payApiURL) {
        throw 2;
    }

    if (document.body) {
        connector._initialize(options, callback);
    } else {
        document.addEventListener("DOMContentLoaded", function () {
            connector._initialize(options, callback);
        }, false);
    }
};

connector.calcBaseUrl = function () {
    return this.config.payApiURL.substring(0, this.config.payApiURL.lastIndexOf("/") + 1);
};

connector._initialize = function (options, callback) {
    this.dataLoader = new SimpleDataLoader();

    Info.initialize();

    var PlatformPluginClass = connector.platform._detectPlugin();
    var platformPlugin = new PlatformPluginClass();
    connector.platform._installPlugin(platformPlugin);

    platformPlugin.initialize(function () {
        SimpleDataLoader.INIT_TYPES(options.migrateLocalStorage);

        platformPlugin.loadUserId();

        this._countryManager = new CountryManager();
        this._paymentsManager = new PaymentsManager(options.products);
        this._adsManager = new AdsManager({
            manualPermissions: options.manualPermissions
        });
        this._socialManager = new SocialManager();

        this._platformStorage.start();

        var SocialPluginClass = connector.social._detectPlugin();
        if (SocialPluginClass) {
            connector.social._installPlugin(new SocialPluginClass());
        }

        var PaymentsPluginClass = connector.payments._detectPlugin();
        if (PaymentsPluginClass) {
            connector.payments._installPlugin(new PaymentsPluginClass());
        }

        var AdsPluginClass = connector.ads._detectPlugin();
        if (AdsPluginClass) {
            connector.ads._installPlugin(new AdsPluginClass());
        }

        callback();

        connector.platform.connect();
        connector.social.connect();
        connector.payments.connect();
        connector.ads.connect();
    }.bind(this));
};