/**
 * Created by mac on 4/8/18
 */

var OKAds = function () {
    AdsPlugin.call(this, connector.OK, {
        sdk: SdkManager.SDK_OK,
        cantLoadAndPlayInParallel: true,
        customImpressionEvent: true
    });
};

OKAds.prototype = Object.create(AdsPlugin.prototype);
OKAds.prototype.constructor = OKAds;

OKAds.isAppropriate = function () {
    return connector.platform.oneOf(connector.OK);
};

OKAds.prototype.isStickySupported = function () {
    return true;
};

OKAds.prototype._checkAdBlock = function (callback) {
    connector.platform.getPlugin().listeners.isAdBlockEnabled = connector.utils.waitNoMore(200, function (result, data) {
        if (result === undefined && data === undefined) {
            this._checkAdBlock(callback);
            return;
        }

        callback(result === "ok" && data === "true");
    }.bind(this));

    FAPI.invokeUIMethod("isAdBlockEnabled");
};

OKAds.prototype.checkAdBlock = function (callback) {
    this._checkAdBlock(callback);
};

OKAds.prototype._connect = function (callback) {
    if (typeof FAPI === "undefined" || !FAPI.UI.showAd || !FAPI.UI.showLoadedAd) {
        callback(connector.STATUS_DISCONNECTED);
        return;
    }

    if (connector.info.isInApp) {
        var isNativeSupported = FAPI.UI.isNativeAdSupported && FAPI.UI.isNativeAdSupported();
        if (!isNativeSupported) {
            callback(connector.STATUS_DISCONNECTED);
            return;
        }
    }

    callback(connector.STATUS_CONNECTED);
};

OKAds.prototype._cacheAd = function (name, callback) {
    if (AdsPlugin.REWARDED === name) {
        this._cacheCallback = callback;
        this.cachedRewardedTime = Date.now();

        FAPI.UI.loadAd();
    } else if (AdsPlugin.INTERSTITIAL === name) {
        callback(connector.CODE_SUCCEED, true);
    }
};

OKAds.prototype._playAd = function (name, ad, callback) {
    if (name === AdsPlugin.REWARDED) {
        this._playRewardedCallback = callback;
        this.cachedRewardedTime = undefined;

        FAPI.UI.showLoadedAd();
    } else if (name === AdsPlugin.INTERSTITIAL) {
        this._playInterstitialCallback = callback;
        FAPI.UI.showAd();
        callback(); // FAPI.UI.showAd is loading ad first, if ad is not loaded it do nothing, and we don't want to wait in this case
    }
};

OKAds.prototype.isAdExpired = function (name) {
    return name === AdsPlugin.REWARDED && this.cachedRewardedTime < Date.now() - OKAds.REWARDED_EXPIRED_TIME;
};

OKAds.prototype.isStickyAvailable = function () {
    if (connector.info.isMobile) {
        if (connector.config.orientation === "vertical") {
            return !this._isLandscape();
        }
        if (!this._isLandscape()) {
            return false;
        }

        return this._setBannerThin();
    }

    return true;
};

OKAds.prototype._isLandscape = function () {
    return window.innerHeight < window.innerWidth;
};

OKAds.prototype._setBannerThin = function () {
    var setThin = function () {
        if (this._bannersData.supported && this._bannersData.supported.thin) {
            connector.platform.getPlugin().listeners.setBannerFormat = connector.utils.once(function (code, data) {
                if (data === "set_banner_format_success" || (code === "ok" && data === "true")) {
                    this._bannersData.current = "thin";
                }
            }.bind(this));

            FAPI.invokeUIMethod("setBannerFormat", "thin");
        }
    }.bind(this);

    if (!this._bannersData) {
        connector.platform.getPlugin().listeners.getBannerFormats = connector.utils.once(function (code, data) {
            if (code === "ok" && data) {
                this._bannersData = JSON.parse(data);
                setThin();
            }
        }.bind(this));

        FAPI.invokeUIMethod("getBannerFormats");
        return false;
    }

    if (this._bannersData.current === "thin") {
        return true;
    }

    setThin();
    return false;
};

OKAds.prototype.showSticky = function (callback) {
    var resultCode = ERRORS.SHOW_STICKY.UNKNOWN;
    var resultSize, code, data;

    var player = new ConnectorActionPlayer([
        function (f) {
            connector.platform.getPlugin().listeners.requestBannerAds = connector.utils.once(f);
            FAPI.invokeUIMethod("requestBannerAds");
        },

        function (f) {
            connector.platform.getPlugin().listeners.isBannerAdsVisible = connector.utils.once(function (c, d) {
                code = c;
                data = d;
                f();
            });
            FAPI.invokeUIMethod("isBannerAdsVisible");
        },

        function (f, stop) {
            if (code !== "ok") {
                stop();
                return;
            }

            var size = { width: OKAds.AD_BANNER_WIDTH, height: OKAds.AD_BANNER_HEIGHT };
            if (connector.info.isMobile) {
                if (this._bannersData && this._bannersData.current === "thin") {
                    size = {
                        width: 0,
                        height: OKAds.AD_THIN_BANNER_HEIGHT_MOB
                    };
                } else {
                    size = {
                        width: 0,
                        height: OKAds.AD_BANNER_HEIGHT_MOB
                    };
                }
            }

            this.bannerSize = size;

            if (data === "true") {
                resultCode = connector.CODE_SUCCEED;
                resultSize = size;
                f();
                return;
            }

            connector.platform.getPlugin().listeners.showBannerAds = connector.utils.once(function (code, data) {
                if (code !== "ok") {
                    stop();
                    return;
                }

                if (data !== "true") {
                    resultCode = ERRORS.SHOW_STICKY.NO_STICKY;
                    stop();
                    return;
                }

                var requestBannerAds = function (code, data) {
                    connector.platform.getPlugin().listeners.requestBannerAds = requestBannerAds;

                    if (code === "ok" && data === "ad_shown") {
                        if (!connector.ads.isStickyPlaying) {
                            connector._adsManager.onStickyRestored(this.bannerSize);
                        }

                        this.bus.trigger("sticky:render");
                    }

                    if (data === "hidden_by_user") {
                        connector._adsManager.onStickyClosedByUser();
                    }
                }.bind(this);

                connector.platform.getPlugin().listeners.requestBannerAds = requestBannerAds;

                callback(connector.CODE_SUCCEED, size);
            }.bind(this));

            FAPI.invokeUIMethod("showBannerAds", "bottom");
        }.bind(this)
    ]);

    player.onFailure(function () {
        callback(resultCode);
    });

    player.play(function () {
        callback(resultCode, resultSize);
    });
};

OKAds.prototype.closeSticky = function (callback) {
    connector.platform.getPlugin().listeners.hideBannerAds = connector.utils.once(function (code) {
        if (code !== "ok") {
            callback(ERRORS.CLOSE_STICKY.UNKNOWN);
            return;
        }

        connector.platform.getPlugin().listeners.isBannerAdsVisible = connector.utils.once(function (code, data) {
            if (code !== "ok") {
                callback(ERRORS.CLOSE_STICKY.UNKNOWN);
                return;
            }

            if (data !== "false") {
                callback(ERRORS.CLOSE_STICKY.UNKNOWN);
                return;
            }

            connector.platform.getPlugin().listeners.requestBannerAds = undefined;
            connector.platform.getPlugin().listeners.showBannerAds = undefined;
            connector.platform.getPlugin().listeners.hideBannerAds = undefined;
            connector.platform.getPlugin().listeners.isBannerAdsVisible = undefined;

            callback(connector.CODE_SUCCEED);
        });

        FAPI.invokeUIMethod("isBannerAdsVisible");
    });

    FAPI.invokeUIMethod("hideBannerAds");
};

OKAds.prototype.onSdkEvent = function (method, result, data) {
    switch (method) {
        case "loadAd":
            this.windowApiLoadAdCallbackRewarded(AdsPlugin.REWARDED, result, data);
            break;
        case "showLoadedAd":
            this.windowApiPlayAdCallbackRewarded(AdsPlugin.REWARDED, result, data);
            break;
        case "showAd":
            this.windowApiCallbackInterstitial(AdsPlugin.INTERSTITIAL, result, data);
            break;
    }
};

OKAds.prototype.windowApiLoadAdCallbackRewarded = function (name, result, data) {
    var callback = this._cacheCallback;
    if (!callback) {
        return;
    }

    if (result === "error") {
        this._cacheCallback = undefined;
        callback(AdsPlugin.ERROR_LOADING_FAILED);
    } else if (["ad_prepared", "ready"].indexOf(data) !== -1) {
        this._cacheCallback = undefined;
        callback(connector.CODE_SUCCEED, true);
    }
};

OKAds.prototype.windowApiPlayAdCallbackRewarded = function (name, result, data) {
    var callback = this._playRewardedCallback;
    if (!callback) {
        return;
    }

    if (["ad_prepared", "ready"].indexOf(data) !== -1) {
        // do nothing
    } else if (result === "error") {
        this._playRewardedCallback = undefined;

        if (data === "skip") {
            callback(ERRORS.SHOW_REWARDED.CLOSED_BY_USER);
        } else if (data === "ad_expired") {
            callback(ERRORS.SHOW_REWARDED.AD_EXPIRED);
        } else {
            callback(ERRORS.SHOW_REWARDED.UNKNOWN);
        }
    } else if (["ad_shown", "complete"].indexOf(data) !== -1) {
        this._playRewardedCallback = undefined;
        this.onImpressionEvent(name);
        callback(connector.CODE_SUCCEED);
    }
};

OKAds.prototype.windowApiCallbackInterstitial = function (name, result, data) {
    var callback = this._playInterstitialCallback;
    if (!callback) {
        return;
    }

    if (["ad_prepared", "ready"].indexOf(data) !== -1) {
        // do nothing
    } else if (result === "error") {
        this._playInterstitialCallback = undefined;

        callback(data === "skip" ? ERRORS.SHOW_INTERSTITIAL.CLOSED_BY_USER : ERRORS.SHOW_INTERSTITIAL.UNKNOWN);
    } else if (["no_ads", "skip"].indexOf(data) !== -1) {
        this._playInterstitialCallback = undefined;
    } else if (["ad_shown", "complete"].indexOf(data) !== -1) {
        this._playInterstitialCallback = undefined;
        this.onImpressionEvent(name);
    }
};

OKAds.AD_BANNER_HEIGHT = 104;
OKAds.AD_BANNER_WIDTH = 404;
OKAds.AD_BANNER_HEIGHT_MOB = 106;
OKAds.AD_THIN_BANNER_HEIGHT_MOB = 56;

OKAds.REWARDED_EXPIRED_TIME = connector.utils.parseInterval("14 minutes");