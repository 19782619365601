/**
 * https://yandex.ru/support2/partner/ru/web/units/types/
 *
 * Created by anatoly on 12.04.24
 */

var YandexWebAds = function () {
    AdsPlugin.call(this, connector.YANDEX_WEB_ADS, {
        sdk: SdkManager.SDK_YANDEX_WEB_ADS,
        cantLoadAndPlayInParallel: true
    });
};

YandexWebAds.prototype = Object.create(AdsPlugin.prototype);
YandexWebAds.prototype.constructor = YandexWebAds;

YandexWebAds.isAppropriate = function () {
    return connector.platform.oneOf(connector.CLEVERAPPS) && connector._countryManager.country === "RU"
        && connector.utils.isIFrame()
        && connector.config.yandexWebAds;
};

YandexWebAds.prototype.isInterstitialSupported = function () {
    return false;
};

YandexWebAds.prototype._playAd = function (name, ad, callback) {
    var platform = Ya.Context.AdvManager.getPlatform();

    window.yaContextCb.push(
        Ya.Context.AdvManager.render({
            blockId: connector.config.yandexWebAds.rewarded[platform],
            type: "rewarded",
            platform: platform,
            onRewarded: function (isRewarded) {
                callback(isRewarded ? connector.CODE_SUCCEED : ERRORS.SHOW_REWARDED.CLOSED_BY_USER);
            },
            onError: function (data) {
                console.log("playAd error: ", data.type);
                callback(ERRORS.SHOW_REWARDED.UNKNOWN);
            }
        }, function () {
            console.log("no rewarded ad");
            callback(ERRORS.SHOW_REWARDED.NO_ADS);
        })
    );
};

YandexWebAds.prototype.isStickyAvailable = function () {
    return window.innerWidth >= YandexWebAds.AD_BANNER_WIDTH;
};

YandexWebAds.prototype.isStickySupported = function () {
    return true;
};

YandexWebAds.prototype.addBannerContainer = function () {
    if (this.bannerContainer) {
        return;
    }

    var container = this.bannerContainer = document.createElement("div");
    container.style.display = "block";
    container.style.position = "absolute";
    container.style.bottom = "0";
    container.style.margin = "0 auto";
    container.style.right = "0";
    container.style.left = "0";
    container.style.height = YandexWebAds.AD_BANNER_HEIGHT + "px";
    container.style.width = YandexWebAds.AD_BANNER_WIDTH + "px";
    container.style.zIndex = "2";
    container.style.backgroundColor = "white";

    var ins = this.bannerIns = document.createElement("ins");
    ins.id = "yandex-ad-banner-container";
    ins.style.zIndex = "2";
    ins.style.display = "inline-block";
    ins.style.position = "relative";
    ins.style.margin = "0 auto";
    ins.style.height = YandexWebAds.AD_BANNER_HEIGHT + "px";
    ins.style.width = YandexWebAds.AD_BANNER_WIDTH + "px";

    document.body.appendChild(container);
    container.appendChild(ins);
};

YandexWebAds.prototype.removeBannerContainer = function () {
    if (this.bannerIns) {
        this.bannerIns.remove();
        this.bannerIns = undefined;
    }
    if (this.bannerContainer) {
        this.bannerContainer.remove();
        this.bannerContainer = undefined;
    }
};

YandexWebAds.prototype.showSticky = function (callback) {
    this.addBannerContainer();

    window.yaContextCb.push(function () {
        Ya.Context.AdvManager.render({
            "blockId": connector.config.yandexWebAds.banner,
            "renderTo": "yandex-ad-banner-container"
        });
    });

    callback(connector.CODE_SUCCEED, {
        width: YandexWebAds.AD_BANNER_WIDTH,
        height: YandexWebAds.AD_BANNER_HEIGHT
    });
};

YandexWebAds.prototype.closeSticky = function (callback) {
    this.removeBannerContainer();

    callback(connector.CODE_SUCCEED);
};

YandexWebAds.AD_BANNER_WIDTH = 728;
YandexWebAds.AD_BANNER_HEIGHT = 90;
