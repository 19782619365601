/**
 * Created by vladislav on 4/2/2024
 */

var SocialDetector = function () {
    if (connector.platform.oneOf(connector.PLIEGA, connector.RUSTORE, connector.PLAYABLE, connector.GDCOM, connector.COOLMATHGAMES, connector.XIAOMI, connector.YOUTUBE, connector.GAMESNACK)
        || connector.platform.oneOf(connector.IOS, connector.MACOS) && !WebViewFacebook.isAvailable() && !AppleSocial.isAvailable()
        || connector.platform.oneOf(connector.AMAZON) && !WebViewFacebook.isAvailable()
        || connector.platform.oneOf(connector.MICROSOFT) && !MicrosoftFacebook.isAvailable()
        || connector.platform.oneOf(connector.CLEVERAPPS) && location.href.includes("scramble.cleverappssg.com")) {
        return NoSocial;
    }

    if (typeof SelectSocial !== "undefined") {
        if (WebViewFacebook.isAvailable()
            && (typeof AndroidSocial !== "undefined" && AndroidSocial.isAvailable() || typeof AppleSocial !== "undefined" && AppleSocial.isAvailable())) {
            return SelectSocial;
        }
    }

    var socials = {
        instant: typeof InstantSocial !== "undefined" && InstantSocial,
        ok: typeof OKSocial !== "undefined" && OKSocial,
        mm: typeof MyMailRuSocial !== "undefined" && MyMailRuSocial,
        vk: typeof VKSocial !== "undefined" && VKSocial,
        sp_mbga: typeof SPMobageSocial !== "undefined" && SPMobageSocial,
        mbga: typeof MobageSocial !== "undefined" && MobageSocial,
        draugiem: typeof DraugiemSocial !== "undefined" && DraugiemSocial,
        fotostrana: typeof FotoStranaSocial !== "undefined" && FotoStranaSocial,
        cleverapps: typeof XsollaSocial !== "undefined" && XsollaSocial,
        yandex: typeof YandexSocial !== "undefined" && YandexSocial,
        macos: typeof AppleSocial !== "undefined" && AppleSocial,
        gpg: typeof AndroidSocial !== "undefined" && AndroidSocial,
        amazon: typeof WebViewFacebook !== "undefined" && WebViewFacebook,
        ios: typeof AppleSocial !== "undefined" && AppleSocial,
        samsung: typeof SamsungSocial !== "undefined" && SamsungSocial,
        mygames: typeof MygamesSocial !== "undefined" && MygamesSocial,
        microsoft: typeof MicrosoftFacebook !== "undefined" && MicrosoftFacebook,
        msstart: typeof MSStartSocial !== "undefined" && MSStartSocial,
        pwa: typeof XsollaSocial !== "undefined" && XsollaSocial,
        plng: typeof PlingaSocial !== "undefined" && PlingaSocial,
        kongregate: typeof KongregateSocial !== "undefined" && KongregateSocial,
        crazy: typeof CrazySocial !== "undefined" && CrazySocial,
        ton: typeof TonSocial !== "undefined" && TonSocial,
        wortal: typeof WortalSocial !== "undefined" && WortalSocial,
        wechat: typeof WechatSocial !== "undefined" && WechatSocial,
        android: typeof AndroidSocial !== "undefined" && AndroidSocial,
        discord: typeof DiscordSocial !== "undefined" && DiscordSocial,
        test: typeof TestSocial !== "undefined" && TestSocial,
        default: typeof FacebookCanvasSocial !== "undefined" && FacebookCanvasSocial
    };

    return socials[connector.info.source] || socials.default;
};
