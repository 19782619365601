/**
 * Created by andrey on 05.10.2020.
 */

var Payments = function (pluginName, options) {
    BasePlugin.call(this, "payments", pluginName, options);

    this.currency = this.getDefaultCurrency();

    this.products = {};
    this.productsById = {};
    this.loadedIds = {};

    this.productsLoaded = false;
    this.bus.on("changeStatus", function (status) {
        if (status !== connector.STATUS_CONNECTED) {
            this.productsLoaded = false;
        }
    }.bind(this));

    this.createProducts();
};

Payments.prototype = Object.create(BasePlugin.prototype);
Payments.prototype.constructor = Payments;

Payments.prototype.getDefaultCurrency = function () {
    return "USD";
};

Payments.prototype.createProducts = function () {
    for (var key in connector._paymentsManager.productsConfig) {
        var product = this.createProduct(key, connector._paymentsManager.productsConfig[key]);
        if (product) {
            this.products[product.itemId] = product;
            this.productsById[product.productId] = product;
        }
    }
};

Payments.prototype.createProduct = function (itemId, productConfig) {
    var productId = this.getProductId(productConfig);
    if (productId === null) {
        return;
    }

    if (productId === undefined) {
        productId = itemId;
    }

    var priceUSD = productConfig.price;
    var price = this.convertPrice(priceUSD);
    var textPrice = this.getTextPrice(price);

    var product = {
        itemId: itemId,
        type: productConfig.type || "consumable",
        title: productConfig.title,
        description: productConfig.description,
        image: productConfig.image,

        productId: productId + "",
        price: price,
        currency: this.currency,
        priceUSD: priceUSD,
        textPrice: textPrice
    };

    if (product.type === "subscription") {
        product.trial = productConfig.trial;
        product.period = productConfig.period;
    }

    if (productConfig.currencyImage) {
        product.currencyImage = productConfig.currencyImage;
    }

    return product;
};

Payments.prototype.getProduct = function (productOrItemId) {
    return this.products[productOrItemId] || this.productsById[productOrItemId];
};

Payments.prototype.listProducts = function () {
    return Object.values(this.products);
};

Payments.prototype.listProductIdsOfType = function (types) {
    types = Array.isArray(types) ? types : [types];

    return this.listProducts().filter(function (product) {
        return types.includes(product.type);
    }).map(function (product) {
        return product.productId;
    });
};

Payments.prototype.isVirtualCurrency = function () {
    return connector.VIRTUAL_EXCHANGE_RATES[this.currency] !== undefined;
};

Payments.prototype.getExchangeRate = function () {
    var exchangeRate;
    if (this.isVirtualCurrency()) {
        exchangeRate = connector.VIRTUAL_EXCHANGE_RATES[this.currency];
    } else {
        exchangeRate = connector.EXCHANGE_RATES[this.currency];
        if (connector.EXCHANGE_RATES[this.pluginName] && connector.EXCHANGE_RATES[this.pluginName][this.currency]) {
            exchangeRate = connector.EXCHANGE_RATES[this.pluginName][this.currency];
        }
    }

    return exchangeRate;
};

Payments.prototype.convertPrice = function (price) {
    var exchangeRate = this.getExchangeRate();

    if (exchangeRate === 1) {
        return price;
    }

    return Math.max(Math.floor(price * exchangeRate + 0.01), 1);
};

Payments.prototype.getPriceToBePaid = function (product) {
    var price = product.price;

    if (connector.config.debugMode || connector.platform.isTester()) {
        price = 1;
    }

    return price;
};

Payments.prototype.getTextPrice = function (price) {
    var currencySign = Payments.CURRENCY_SIGNS[this.currency] || this.currency;

    if (["USD", "EUR", "GBP"].includes(this.currency)) {
        return currencySign + price;
    }

    return price + " " + currencySign;
};

Payments.prototype.getProductId = function () {

};

Payments.prototype.setCurrency = function (currency) {
    if (!connector.EXCHANGE_RATES[currency] && !connector.VIRTUAL_EXCHANGE_RATES[currency]) {
        return;
    }

    if (currency !== this.currency) {
        this.currency = currency;

        for (var key in this.products) {
            var product = this.products[key];

            product.price = this.convertPrice(product.priceUSD);
            product.currency = currency;
            product.textPrice = this.getTextPrice(product.price);
        }
    }
};

Payments.prototype.onRemoved = function () {
    connector._paymentsManager.stop();
};

Payments.prototype.setPurchaseState = function (purchaseState) {
    if (this.purchaseState !== purchaseState) {
        this.purchaseState = purchaseState;

        if (this.purchaseState === undefined) {
            this.bus.trigger("paymentClosed");
        }
    }
};

Payments.prototype.isIdle = function () {
    return this.purchaseState === undefined;
};

Payments.prototype.getPurchaseState = function () {
    return this.purchaseState;
};

Payments.prototype.restore = function (callback) {
    callback(connector.CODE_FAILED);
};

Payments.prototype.consume = function (purchase, callback) {
    callback(connector.CODE_SUCCEED);
};

Payments.prototype.purchase = function (product, callback) {
    callback(connector.CODE_FAILED);
};

Payments.prototype.validate = function (purchase, callback) {
    callback(connector.CODE_SUCCEED, purchase);
};

Payments.prototype.createPurchase = function (paymentId, product, payload) {
    var userId = String(connector.platform.getUserID())
        .replace("sb.mbga.jp:", "")
        .replace("mbga.jp:", "")
        .replace("FS_", "");

    var purchase = {
        userId: userId,
        productId: product.productId,
        source: connector.info.source,
        method: this.pluginName,
        price: this.getPriceToBePaid(product),
        currency: product.currency || "USD",
        type: product.type,
        payload: payload || {}
    };

    if (paymentId) {
        purchase.paymentId = String(paymentId);
    }

    return purchase;
};

Payments.prototype.getSubscriptionStatus = function (options, callback) {
    var source = connector.info.source;
    if (["android", "ios", "macos"].includes(source) && Object.keys(options.tokens).length === 0) {
        return;
    }

    if (connector.platform.withTmpID() && ["facebook", "ok"].includes(source)) {
        return;
    }

    var params = { source: source, tokens: options.tokens };

    ConnectorRestClient.post("/subscriptions/get/" + encodeURIComponent(connector.platform.getUserID()), params, callback);
};

Payments.prototype.loadProducts = function (callback) {
    if (this.productsLoaded) {
        callback(connector.CODE_SUCCEED);
        return;
    }

    this._loadProducts(function (code, loadedIds) {
        if (code !== connector.CODE_SUCCEED) {
            callback(code);
            return;
        }

        if (loadedIds.length > 0) {
            this.productsLoaded = true;
        }

        this.verifyLoadedProducts(loadedIds);
        this.bus.trigger("productsLoaded");

        callback(code);
    }.bind(this));
};

Payments.prototype._loadProducts = function (callback) {
    var loadedIds = this.listProductIdsOfType(["consumable", "nonConsumable"]);

    callback(connector.CODE_SUCCEED, loadedIds);
};

Payments.prototype.loadSubscriptions = function (callback) {
    if (this.subscriptionsLoaded) {
        callback(connector.CODE_SUCCEED);
        return;
    }

    var subscriptionsIds = this.listProductIdsOfType("subscription");

    if (subscriptionsIds.length === 0) {
        this.subscriptionsLoaded = true;
        callback(connector.CODE_SUCCEED);
        return;
    }

    this._loadSubscriptions(function (code, loadedIds) {
        if (code !== connector.CODE_SUCCEED) {
            callback(code);
            return;
        }

        if (loadedIds.length > 0) {
            this.subscriptionsLoaded = true;
        }

        this.verifyLoadedSubscriptions(loadedIds);

        callback(code);
    }.bind(this));
};

Payments.prototype._loadSubscriptions = function (callback) {
    var loadedIds = this.listProductIdsOfType("subscription");

    callback(connector.CODE_SUCCEED, loadedIds);
};

Payments.prototype.loadSubscriptionsTokens = function (callback) {
    if (this.subscriptionsTokensLoaded) {
        callback(connector.CODE_SUCCEED);
        return;
    }

    var subscriptionsIds = this.listProductIdsOfType("subscription");

    if (subscriptionsIds.length === 0) {
        this.subscriptionsTokensLoaded = true;
        callback(connector.CODE_SUCCEED);
        return;
    }

    this._loadSubscriptionsTokens(function (code) {
        if (code === connector.CODE_SUCCEED) {
            this.subscriptionsTokensLoaded = true;
        }

        callback(code);
    }.bind(this));
};

Payments.prototype._loadSubscriptionsTokens = function (callback) {
    callback(connector.CODE_SUCCEED);
};

Payments.prototype.verifyLoadedSubscriptions = function (loadedIds) {
    var missingProducts = this._getMissingProducts("subscription", loadedIds);

    if (missingProducts.length > 0) {
        this.bus.trigger("error:unknownSubscriptions", missingProducts);
    }
};

Payments.prototype.verifyLoadedProducts = function (loadedIds) {
    var missingProducts = this._getMissingProducts(["consumable", "nonConsumable"], loadedIds);

    if (missingProducts.length > 0) {
        this.bus.trigger("error:unknownProducts", missingProducts);
    }
};

Payments.prototype._getMissingProducts = function (types, loadedIds) {
    var productsIds = this.listProductIdsOfType(types);

    return connector.utils.substract(productsIds, loadedIds);
};

Payments.prototype.isRestoreAvailable = function () {
    return connector.payments.isConnected();
};

Payments.prototype.notifyActive = function () {

};

Payments.CURRENCY_SIGNS = {
    USD: "$",
    EUR: "€",
    GBP: "£",
    RUB: "₽",
    CNY: "￥",

    MLK: "млк",
    FM: "ФМ"
};

Payments.ERROR_INVALID_EMAIL = "INVALID_EMAIL";