/**
 * Created by vladislav on 2/9/2024
 */

/**
 * Working with ads is implemented via **connector.ads** interface. Connector supports three types of advertisement: rewarded, interstitial and sticky banner.
 *
 * ## Share
 *
 *
 * ### Supported Platforms
 * <ul class="platforms-list">
 *  <li>
 *      ✅ <img src="images/yandex.png"> Yandex Games
 *  </li>
 *  <li>
 *      ✅ <img src="images/vk.png"> VK Games
 *  </li>
 *  <li>
 *      ✅ <img src="images/ok.png"> OK Games
 *  </li>
 *  <li>
 *       ✅ <img src="images/crazy.png"> Crazy
 *  </li>
 *  <li>
 *      ✅ <img src="images/instant.png"> Facebook Instant Games
 *  </li>
 *  <li>
 *      ✅ <img src="images/android.png"> Android
 *  </li>
 *  <li>
 *      ✅ <img src="images/amazon.png"> Amazon
 *  </li>
 *   <li>
 *      ✅ <img src="images/apple.png"> iOS
 *  </li>
 *  <li>
 *      ✅ <img src="images/msn.png"> MSN
 *  </li>
 *  <li>
 *      ✅ <img src="images/rustore.png"> Rustore
 *  </li>
 *  <li>
 *      ✅ <img src="images/samsung.png"> Samsung
 *  </li>
 *  <li>
 *      ✅ <img src="images/plinga.png"> Plinga
 *  </li>
 *   <li>
 *      ✅ <img src="images/mi.png"> Xiaomi
 *  </li>
 *  <li>
 *      ✅ <img src="images/gdcom.png"> GDCom
 *  </li>
 *  <li>
 *      ✅ <img src="images/wortal.png"> Wortal
 *  </li>
 * </ul>
 *
 * Available properties:
 *  ```
 * // Is AdBlock enabled
 * connector.ads.isAdBlockEnabled;
 *
 * // Is the banner available
 * connector.ads.isStickyAvailable;
 * connector.ads.isInterstitialAvailable;
 * connector.ads.isRewardedAvailable;
 *
 * // Is the ads playing now
 * connector.ads.isStickyPlaying;
 * connector.ads.isInterstitialPlaying;
 * connector.ads.isRewardedPlaying;
 *  ```
 *
 * ## Rewarded
 *
 * Rewarded Video is a non-skippable video ad that aims to give the player a reward for watching. It is forbidden to show without a reward.
 *
 * ```
 * // Show rewarded video, returns a promise
 * const success = await connector.ads.showRewardedVideo();
 * if (success) {
 *     // success
 * }
 *
 * // Showing started
 * connector.ads.on('rewarded:start', () => {});
 * // Showing ended
 * connector.ads.on('rewarded:close', (success) => {});
 * // Reward is received
 * connector.ads.on('rewarded:reward', () => {});
 * ```
 *
 * ## Interstitial
 *
 * Interstitial is pop-up, often fullscreen skippable (sometimes only after a few seconds) advertising.
 * Usually it is shown in transition between levels. Its displaying is prohibited during the gameplay on many platforms. It is allowed to show only in pause between game sessions.
 * It is also prohibited during the navigation by the VK Games platform. So, we will consider this a bad practice on other platforms too.
 *
 * ```
 * // Show interstitial, returns a promise
 * connector.ads.showInterstitial();
 *
 * // Showing started
 * connector.ads.on('interstitial:start', () => {});
 * // Showing ended
 * connector.ads.on('interstitial:close', (success) => {});
 * ```
 *
 * ## Sticky banner
 *
 * Sticky banner is a fixed bottom banner. It takes ~50-100px (110px VK Direct Games). The banner must not cover the play region.
 *
 * ### Supported Platforms
 * <ul class="platforms-list">
 *  <li>
 *      ✅ <img src="images/yandex.png"> Yandex Games
 *  </li>
 *  <li>
 *      ✅ <img src="images/vk.png"> VK Games
 *  </li>
 *  <li>
 *      ✅ <img src="images/ok.png"> OK Games
 *  </li>
 *  <li>
 *      ✅ <img src="images/instant.png"> Facebook Instant Games (only in mobile app)
 *  </li>
 *  <li>
 *      ✅ <img src="images/android.png"> Android
 *  </li>
 *    <li>
 *      ✅ <img src="images/apple.png"> iOS
 *  </li>
 *  <li>
 *      ✅ <img src="images/msn.png"> MSN
 *  </li>
 *  <li>
 *      ✅ <img src="images/rustore.png"> Rustore
 *  </li>
 *  <li>
 *      ✅ <img src="images/samsung.png"> Samsung
 *  </li>
 *  <li>
 *      ✅ <img src="images/mi.png"> Xiaomi
 *  </li>
 * </ul>
 *
 * ```
 * // Show the sticky banner, then it will auto-update itself
 * connector.ads.showSticky();
 * // Refresh the sticky banner, forced refreshing
 * connector.ads.refreshSticky();
 * // Close the sticky banner
 * connector.ads.closeSticky();
 *
 * // Open the banner
 * connector.ads.on('sticky:start', () => {});
 * // The banner appears on the screen
 * connector.ads.on('sticky:render', () => {});
 * // The banner updated
 * connector.ads.on('sticky:refresh', () => {});
 * // Close the banner
 * connector.ads.on('sticky:close', () => {});
 * ```
 *
 * @namespace Ads
 * */

var AdsInterface = function () {
    var eCPM = {
        rewarded: {}
    };

    eCPM[connector.INSTANT] = 2.12;
    eCPM[connector.OK] = 50 / connector.EXCHANGE_RATES.RUB;
    eCPM[connector.GDCOM] = 1.7 / connector.EXCHANGE_RATES.EUR;
    eCPM[connector.PLINGA] = 0.85 / connector.EXCHANGE_RATES.EUR;
    eCPM[connector.VK] = 36 / connector.EXCHANGE_RATES.RUB;
    eCPM[connector.YANDEX] = 0.66;
    eCPM[connector.YANDEX_WEB_ADS] = 38 / connector.EXCHANGE_RATES.RUB;
    eCPM[connector.YANDEX_APP_ADS] = 1.4;
    eCPM[connector.GOOGLE_ADS] = 2.96 / connector.EXCHANGE_RATES.SGD;
    eCPM[connector.MSSTART] = 2.0;
    eCPM[connector.SAMSUNG] = 1.46;
    eCPM[connector.CRAZY] = 1.0;
    eCPM[connector.WECHAT] = 50 / connector.EXCHANGE_RATES.CNY;
    eCPM[connector.TEST] = 1.0;

    eCPM.rewarded[connector.INSTANT] = 2.04;
    eCPM.rewarded[connector.GOOGLE_ADS] = 6.15 / connector.EXCHANGE_RATES.SGD;
    eCPM.rewarded[connector.CRAZY] = 1.0;
    eCPM.rewarded[connector.VK] = 36 / connector.EXCHANGE_RATES.RUB;
    eCPM.rewarded[connector.MSSTART] = 2.0;
    eCPM.rewarded[connector.SAMSUNG] = 2.13;
    eCPM.rewarded[connector.GDCOM] = 0.4 / connector.EXCHANGE_RATES.EUR;
    eCPM.rewarded[connector.WECHAT] = 50 / connector.EXCHANGE_RATES.CNY;
    eCPM.rewarded[connector.TEST] = 1.0;
    eCPM.rewarded[connector.PLINGA] = 0.85 / connector.EXCHANGE_RATES.EUR;
    eCPM.rewarded[connector.OK] = 50 / connector.EXCHANGE_RATES.RUB;
    eCPM.rewarded[connector.YANDEX] = 0.37;
    eCPM.rewarded[connector.YANDEX_APP_ADS] = 1.4;
    eCPM.rewarded[connector.YANDEX_WEB_ADS] = 38 / connector.EXCHANGE_RATES.RUB;

    return Object.assign({
        /**
         * @type {Boolean}
         * @memberof Ads
         * @desc Is rewarded ad supported on this platform
         * */
        get isRewardedSupported() {
            return this.getPlugin().isRewardedSupported();
        },
        /**
         * @type {Boolean}
         * @memberof Ads
         * @desc Is rewarded ad available now
         * */
        get isRewardedAvailable() {
            return connector._adsManager.getRewardedStatus() === connector.CODE_SUCCEED;
        },
        /**
         * @type {Boolean}
         * @memberof Ads
         * @desc Is rewarded ad playing now
         * */
        get isRewardedPlaying() {
            return this.getPlugin().isPlaying(AdsPlugin.REWARDED);
        },
        /**
         * @type {Boolean}
         * @memberof Ads
         * @desc Is interstitial ad supported on this platform
         * */
        get isInterstitialSupported() {
            return this.getPlugin().isInterstitialSupported();
        },
        /**
         * @type {Boolean}
         * @memberof Ads
         * @desc Is interstitial ad available now
         * */
        get isInterstitialAvailable() {
            return connector._adsManager.getInterstitialStatus() === connector.CODE_SUCCEED;
        },
        /**
         * @type {Boolean}
         * @memberof Ads
         * @desc Is interstitial ad playing now
         * */
        get isInterstitialPlaying() {
            return this.getPlugin().isPlaying(AdsPlugin.INTERSTITIAL);
        },
        /**
         * @type {Boolean}
         * @memberof Ads
         * @desc Is sticky banner supported on this platform
         * */
        get isStickySupported() {
            return this.getPlugin().isStickySupported();
        },

        /**
         * @type {Boolean}
         * @memberof Ads
         * @desc Is sticky banner ad available now
         * */
        get isStickyAvailable() {
            return connector._adsManager.getStickyStatus() === connector.CODE_SUCCEED;
        },
        /**
         * @type {Boolean}
         * @memberof Ads
         * @desc Is sticky banner ad playing now
         * */
        get isStickyPlaying() {
            return connector._adsManager.isStickyPlaying();
        },
        /**
         * @type {Boolean}
         * @memberof Ads
         * @desc Does user have an ad block
         * */
        get isAdBlockEnabled() {
            return connector._adsManager.isAdBlockEnabled;
        }
    }, CommonInterface(), {
        _detectPlugin: AdsDetector,

        checkAdBlock: function () {
            return new Promise(function (resolve) {
                this.checkAdBlockCallback(resolve);
            }.bind(this));
        },

        checkAdBlockCallback: function (callback) {
            connector._adsManager.checkAdBlock(callback);
        },
        /**
         * @function showRewardedVideo
         * @memberof Ads
         * @desc Show rewarded ad video
         * @return {Promise<Boolean>}
         * */
        showRewardedVideo: function () {
            return new Promise(function (resolve) {
                this.showRewardedVideoCallback(resolve);
            }.bind(this));
        },

        showRewardedVideoCallback: function (callback) {
            connector._adsManager.showRewarded(function (code) {
                callback(code === connector.CODE_SUCCEED);
            });
        },
        /**
         * @function showInterstitial
         * @memberof Ads
         * @desc Show interstitial ad video
         * @return {Promise<Boolean>}
         * */
        showInterstitial: function () {
            return new Promise(function (resolve) {
                this.showInterstitialCallback(resolve);
            }.bind(this));
        },

        showInterstitialCallback: function (callback) {
            connector._adsManager.showInterstitial(function (code) {
                callback(code === connector.CODE_SUCCEED);
            });
        },

        askPermission: function (f) {
            connector._adsManager.askPermission(f);
        },

        finishAd: function (name, code) {
            var plugin = this.getPlugin();
            if (plugin) {
                plugin.finishAd(name, code);
            }
        },
        /**
         * @function showSticky
         * @memberof Ads
         * @desc Show sticky banner
         * @return {Promise<Boolean>}
         * */
        showSticky: function () {
            return new Promise(function (resolve) {
                this.showStickyCallback(resolve);
            }.bind(this));
        },

        showStickyCallback: function (callback) {
            connector._adsManager.enableSticky(function () {
                callback && callback();
            });
        },
        /**
         * @function refreshSticky
         * @memberof Ads
         * @desc Refresh sticky banner
         * @return {Promise<Boolean>}
         * */
        refreshSticky: function () {
            return new Promise(function (resolve) {
                this.refreshStickyCallback(resolve);
            }.bind(this));
        },

        refreshStickyCallback: function (callback) {
            connector._adsManager.refreshSticky(function () {
                callback && callback();
            });
        },
        /**
         * @function closeSticky
         * @memberof Ads
         * @desc Close sticky banner
         * @return {Promise<Boolean>}
         * */
        closeSticky: function () {
            return new Promise(function (resolve) {
                this.closeStickyCallback(resolve);
            }.bind(this));
        },

        closeStickyCallback: function (callback) {
            connector._adsManager.disableSticky(function () {
                callback && callback();
            });
        },

        eCPM: eCPM
    });
};