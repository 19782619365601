/**
 * Created by andrey on 31.01.2024
 */

var MultiAdsPlugin = function () {
    this.plugins = {
        msstart: new MSStartAds(),
        google: new GoogleWebAds()
    };

    AdsPlugin.call(this, connector.MULTI_ADS, {
        cantLoadAndPlayInParallel: true
    });

    Object.values(this.plugins).forEach(function (plugin) {
        AdsPlugin.TYPES.forEach(function (type) {
            plugin.bus.on(type + ":caching", function (plugin) {
                this.bus.trigger(type + ":caching", plugin);
            }.bind(this));

            plugin.bus.on(type + ":cached", function (code, plugin) {
                this.bus.trigger(type + ":cached", code, plugin);
            }.bind(this));

            plugin.bus.on(type + ":impression", function (impressionCost, plugin) {
                this.bus.trigger(type + ":impression", impressionCost, plugin);
            }.bind(this));

            plugin.bus.on(type + ":refresh", function () {
                this.bus.trigger(type + ":refresh");
            }.bind(this));
        }.bind(this));
    }.bind(this));
};

MultiAdsPlugin.prototype = Object.create(AdsPlugin.prototype);
MultiAdsPlugin.prototype.constructor = MultiAdsPlugin;

MultiAdsPlugin.prototype.code = MultiAdsPlugin.code = "MultiAdsPlugin";

MultiAdsPlugin.prototype._askPermission = function (callback) {
    var plugins = Object.values(this.plugins);

    callback = connector.utils.wait(plugins.length, callback);

    plugins.forEach(function (plugin) {
        plugin.askPermission(callback);
    });
};

MultiAdsPlugin.prototype.connect = function () {
    if (this.isConnected()) {
        Object.values(this.plugins).forEach(function (plugin) {
            plugin.connect();
        });
    }

    AdsPlugin.prototype.connect.call(this);
};

MultiAdsPlugin.prototype._connect = function (callback) {
    var plugins = Object.values(this.plugins);
    var connected = false;

    var oneCallback = connector.utils.wait(plugins.length, function () {
        callback(connected ? connector.STATUS_CONNECTED : connector.STATUS_DISCONNECTED);
    });

    plugins.forEach(function (plugin) {
        plugin.bus.once("changeStatus", connector.utils.waitNoMore(9000, function (status) {
            if (status === connector.STATUS_CONNECTED) {
                connected = true;
            }

            oneCallback();
        }));
        plugin.connect();
    });
};

MultiAdsPlugin.prototype.cacheAd = function (name, callback) {
    if (this.isAdCached(name) && !this.isAdExpired(name)) {
        Object.values(this.plugins).forEach(function (plugin) {
            plugin.cacheAd(name);
        });
    }

    AdsPlugin.prototype.cacheAd.call(this, name, callback);
};

MultiAdsPlugin.prototype.cacheAdInner = function (name, callback) {
    this.setLoaderState(name, AdsPlugin.LOADER_STATE.LOADING);

    var plugins = Object.values(this.plugins);
    var counter = plugins.length;

    var onCompleted = connector.utils.once(function (code) {
        if (code === connector.CODE_SUCCEED) {
            this.cached[name] = true;
            this.setLoaderState(name, AdsPlugin.LOADER_STATE.READY);
        } else {
            this.cachedErrorTime[name] = Date.now();
            this.setLoaderState(name, AdsPlugin.LOADER_STATE.IDLE);
        }

        callback(code);
    }.bind(this));

    Object.values(this.plugins).forEach(function (plugin) {
        plugin.cacheAd(name, function (code) {
            counter--;

            if (code === connector.CODE_SUCCEED) {
                onCompleted(code);
                return;
            }

            if (counter === 0) {
                onCompleted(connector.CODE_FAILED);
            }
        });
    });
};

MultiAdsPlugin.prototype.playAd = function (name, callback) {
    callback = callback || function () {};

    var pluginNames = Object.keys(this.plugins);

    this.cached[name] = undefined;
    this.cachedErrorTime[name] = undefined;

    this.setLoaderState(name, AdsPlugin.LOADER_STATE.IDLE);
    this.setVideoState(name, AdsPlugin.VIDEO_STATE.PLAYING);

    this.bus.trigger(name + ":start", this.pluginName);

    var finish = function (plugin, code) {
        // console.log("finish ad", code);

        this.finishTime[name] = Date.now();

        this.setVideoState(name, AdsPlugin.VIDEO_STATE.IDLE);

        var success = code === connector.CODE_SUCCEED;

        this.bus.trigger(name + ":close", success, plugin.pluginName);
        if (name === AdsPlugin.REWARDED && success) {
            this.bus.trigger("rewarded:reward", plugin.pluginName);
        }

        callback(code);
    }.bind(this);

    var playNext = function () {
        var plugin = this.plugins[pluginNames.shift()];

        console.log("Play " + plugin.pluginName);

        var player = new ConnectorActionPlayer([
            function (success, failure) {
                plugin.cacheAd(name, function (code) {
                    if (code === connector.CODE_SUCCEED) {
                        success();
                    } else {
                        failure(name === AdsPlugin.REWARDED ? ERRORS.SHOW_REWARDED.NO_ADS : ERRORS.SHOW_INTERSTITIAL.NO_ADS);
                    }
                });
            },

            function (success, failure) {
                plugin.playAd(name, connector.utils.once(function (code) {
                    if (code === connector.CODE_SUCCEED) {
                        success();
                    } else {
                        failure(code);
                    }
                }));
            }
        ]);

        player.onSuccess(finish.bind(this, plugin, connector.CODE_SUCCEED));
        player.onFailure(function (code) {
            if (pluginNames.length === 0) {
                finish(plugin, code);
            } else {
                playNext();
            }
        });

        player.play();
    }.bind(this);

    playNext();
};

MultiAdsPlugin.prototype.isStickySupported = function () {
    return this.plugins.google.isStickySupported();
};

MultiAdsPlugin.prototype.isStickyAvailable = function () {
    return this.plugins.google.isStickyAvailable();
};

MultiAdsPlugin.prototype.showSticky = function (callback) {
    this.plugins.google.showSticky(callback);
};

MultiAdsPlugin.prototype.closeSticky = function (callback) {
    this.plugins.google.closeSticky(callback);
};

MultiAdsPlugin.isAppropriate = function () {
    return MSStartAds.isAppropriate() && GoogleWebAds.isAppropriate();
};
