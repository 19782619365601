/**
 * Created by Andrey Popov on 03.11.2023
 *
 * https://docs.crazygames.com/sdk/html5/video-ads/
 */

var CrazyAds = function () {
    AdsPlugin.call(this, connector.CRAZY, {
        sdk: SdkManager.SDK_CRAZY
    });

    this.alwaysRefreshSticky = true;
};

CrazyAds.prototype = Object.create(AdsPlugin.prototype);
CrazyAds.prototype.constructor = CrazyAds;

CrazyAds.isAppropriate = function () {
    return connector.platform.oneOf(connector.CRAZY);
};

CrazyAds.prototype.isStickySupported = function () {
    return true;
};

CrazyAds.prototype.checkAdBlock = function (callback) {
    CrazyGames.SDK.ad.hasAdblock(function (error, result) {
        callback(!error && result);
    });
};

CrazyAds.prototype._playAd = function (name, ad, callback) {
    CrazyGames.SDK.ad.requestAd((name === AdsPlugin.REWARDED ? "rewarded" : "midgame"), {
        adStarted: function () {},
        adFinished: function () {
            callback(connector.CODE_SUCCEED);
        },
        adError: function () {
            if (name === AdsPlugin.REWARDED) {
                callback(ERRORS.SHOW_REWARDED.UNKNOWN);
            } else {
                callback(ERRORS.SHOW_INTERSTITIAL.UNKNOWN);
            }
        }
    });
};

CrazyAds.prototype._cacheAd = function (name, callback) {
    CrazyGames.SDK.ad.hasAdblock(function (error, result) {
        if (error || result) {
            callback(AdsPlugin.ERROR_LOADING_FAILED);
        } else {
            callback(connector.CODE_SUCCEED, true);
        }
    });
};

CrazyAds.prototype.showSticky = function (callback) {
    var container = document.getElementById("crazygames-ad-banner-container");

    if (!container) {
        container = document.createElement("div");
        container.id = "crazygames-ad-banner-container";
        container.style.display = "block";
        container.style.visibility = "hidden";
        container.style.position = "absolute";
        container.style.zIndex = "2";
        container.style.bottom = "0";
        container.style.margin = "0 auto";
        container.style.height = CrazyAds.AD_BANNER_HEIGHT + "px";
        container.style.width = "100%";
        document.body.appendChild(container);
    }

    var onBannerReady = function (error) {
        if (error) {
            container.remove();
            callback(error);
            return;
        }

        container.style.visibility = "visible";

        callback(connector.CODE_SUCCEED, {
            width: 0,
            height: CrazyAds.AD_BANNER_HEIGHT
        });
    };

    CrazyGames.SDK.banner.requestResponsiveBanner(
        "crazygames-ad-banner-container",
        onBannerReady
    );
};

CrazyAds.prototype.refreshSticky = function (callback) {
    CrazyGames.SDK.banner.requestResponsiveBanner("crazygames-ad-banner-container", function (error) {
        if (error) {
            callback(connector.CODE_FAILED, error);
        } else {
            callback(connector.CODE_SUCCEED);
        }
    });
};

CrazyAds.prototype.closeSticky = function (callback) {
    CrazyGames.SDK.banner.clearAllBanners();

    var container = document.getElementById("crazygames-ad-banner-container");
    if (container) {
        container.remove();
    }

    callback(connector.CODE_SUCCEED);
};

CrazyAds.AD_BANNER_HEIGHT = 50;
